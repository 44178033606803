.about {
  padding: 6rem 3rem 5rem 3rem;
  background: #f8f8f8;
}

.row {
  display: flex;
  justify-content: space-around;
}

.row .column {
  width: 40%;
}

.column .tabs {
  display: flex;
}

.tabs {
  position: relative;

}

.single-tab {
  background: white;
  margin-right: 10px;
  cursor: pointer;
}

.single-tab h2 {
  display: block;
  padding: 10px 16px;
  border-radius: 4px;
  color: #3c3c3c;
  font-size: 14px;
  font-weight: 600;
}

.about-img {
  position: relative;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 95%;
}

.content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: none;
}


.active-content {
  display: block;
}

.active-tab {
  background: #b7e4c7;
  position: relative;
}

.active-tab::before {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #b7e4c7 transparent transparent transparent;
}

.active-tab h2 {
  color: white;
}

.consultasText {
  margin-top: 10px;
}

.content h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 1rem;
}

.content p {
  padding-bottom: 1rem;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  color: #4d4d4d;
}

.content h3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 1rem;
}


#subtitle {
  margin-top: 28px;
}


/* Experience CSS */
.exp-column h3 {
  margin-bottom: 10px;
}

.exp-column span {
  font-weight: 500;
  color: #b7e4c7;
}

.exp-column p {
  margin-top: 10px;
}

.HDtextdiv {
  margin-right: 20px !important;
  width: 80% !important;
}

.consultasText {
  width: auto;
}

#mapaImg {
  width: 80%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

#livroImg {
  width: 80%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}



#btnConsultas {

  border: none;
  background-color: white;
  border: 1px solid #49734F;
  color:#49734F;
}

#btnConsultas:hover {
  background-color: #49734F !important;
  color: white;
}

#btnConsultas:active {
  background-color: #49734F;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}


/* @media screen and (max-width: 1320px) {
  #tabs2 {
    margin-left: 4.5% !important;
  }
}

@media screen and (max-width: 1570px) {
  #tabs2 {
    margin-left: 6% !important;
  }
} */
/*
@media screen and (max-width: 1600px) {
  #tabs2 {
    margin-left: 7% !important;
  }
}

@media screen and (max-width: 1550px) {
  #tabs2 {
    margin-left: 5% !important;
  }
}

@media screen and (max-width: 320px) {
  #tabs2 {
    margin-left: 3% !important;
  }
} */

@media screen and (max-width:768px) {
  .about {
    padding: 2rem 1rem 3rem 1rem;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  .row .column {
    width: 100%;
  }

  .tabs {
    padding-top: 3rem;
  }

  .skills-row {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 769px) {
  .about-img img {
    width: 50%;

  }
}

/* @media screen and (max-width: 1610px) {
  #tabs2 {
    left: 108px;

  }
} */
