@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,200;0,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Mulish:200,300,regular,500,600,700,800,900,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic');
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,200;0,500;1,700&family=Open+Sans&display=swap');
@import "./assets/img/css/about.css";
@import "./assets/img/css/about2.css";
@import "./assets/img/css/animation.css";
@import "./assets/img/css/card.css";
@import "./assets/img/css/aboutPage.css";
@import "./assets/img/css/navbar.css";

/* commit ocd */
* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;

}

.App {
  background: #f8f8f8;
}

:root {
  --mainColor: #49734F;
  --mainColorLight: #49734F;
  --secondaryColor: #37593C;
  --textColor: black;
  --titles-font: 20px;
  --secondary-text-font: 16px;
  --smaller-screen: 650px;
  /* --main-font: "Mulish", regular; */
  --main-font: 'Bitter', serif;
  --letter-spacing: 1px;
  --smaller-screen: 870px;
}



/* #navbar2 {
  margin: 0 !important;
  background-color: #f8f8f8 !important;

}

#nav-links {
  position: relative;
  color: var(--mainColor);
}

#nav-links::after {
  content: '';
  transition: all 0.6s;
  opacity: 0;
  height: 2px;
  width: 100%;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 0;

}

#nav-links:hover::after {
  opacity: 1;

}

.navbar-toggler-icon {
  background-color: #f8f8f8 !important;
  border-radius: 7px;
  border: 1px solid #49734F;
  color: #000;
  opacity: 0.9;
  box-shadow: 1px 1px 1px grey;
}

#divcollapse {
  width: 100%;
  background-color: #f8f8f8;


}

.navbar-toggler {
  right: 5%;
  position: absolute
} */



.carousel {
  position: relative;
  max-height: 94vh;
}

.banner .container {
  margin-top: 5%;
}

.banner {
  background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(./assets/img/pic6.jpg);
  background-size: cover;
  padding: 35px 0;
  height: 90vh;
  text-align: center;
  margin-top: 89px;
}

.banner h1 {
  margin: 0;
  color: #f8f8f8;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  font-size: 250%;
  font-weight: bold;
  line-height: 2;
}

.banner p {
  font-size: 20px;
  color: #f8f8f8;
  opacity: .7;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}

#pullBtn {
  background-color: #f8f8f8;
  color: black ;
  cursor: pointer;
  text-align: center;
  padding: 5px 15px;
  border-radius: 15px;
  animation: fadeIn 10s;
  margin-top: 5%;
}

#pullBtn:hover {
  background-color: #cdc1c1;

}

@keyframes fadeIn {
  0% { opacity: 0; }
 100% { opacity: 0.5; }
}

#pullBtn:active {

  transform: translateY(4px);
}


.carousel h5 {

  color: #f8f8f8;

  font-size: 6vw !important;
}

.carousel p {
  font-size: 3vw !important;
  color: white !important;
  margin-bottom: 0 auto;
}

.carousel-caption {
  margin-bottom: 5% !important;
}

#banner-picture {
  height: 90vh !important;
  max-width: auto;
}

#sobre_div {

  border-radius: 20px;
  margin-top: 15%;
}

#sobre {
  background-color: #243E28 !important;
  color: white;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  position: relative;
  box-shadow: 30px 30px 30px grey;
}

#tituloSobre {

  border-bottom: 3px solid white;
  margin-left: 10%;
  margin-top: 5%;
  padding: 0 !important;
}

#sobre h1 {

  border-bottom: 3px solid white;
  margin-left: 10%;
  position: absolute;

}

#sobre p {
  padding: 0 10% 0 10%;
  margin-top: 20%;
  margin-bottom: 10%;
}

#sobre_div img {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  box-shadow: 30px 30px 30px grey !important;
}

#HD_div {

  border-radius: 20px;
  margin-top: 15%;
}

#HD {
  background-color: #243E28 !important;
  color: white;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  position: relative;
  box-shadow: 30px 30px 30px grey;
}

#HD h1 {
  display: inline;
  border-bottom: 3px solid white;
  position: absolute;

}

#HD p {
  padding: 0 10% 0 10%;
  margin-top: 20%;
}

#HD_div img {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  box-shadow: 30px 30px 30px grey;
}

#tituloHD {
  display: inline;
  border-bottom: 3px solid white;
  margin-left: 10%;
  padding-top: 5%;

}

/* BANNER CSS*/

#btn2 {
  background-color: #49734F;
  justify-content: space-between !important;
  margin-left: 10%;
}

#btn2 svg {
  margin-left: 10px;
  margin-right: 5px;
}

#home {
  margin-top: 100px;
  display: inline-block;
  flex-wrap: wrap;
  position: relative;
  width: 2000px;
}

/* BANNER HD CSS*/

#bannerContainerHD img {
  width: 100% !important;
  height: 100% !important;
  opacity: 1;
  padding: 0;
}

.humanDesign a {
  width: 100%;
  height: 90vh;
  box-sizing: border-box;
}

.humanDesignRow {
  background-color: #49734F;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.6);
  width: 2000px;
}

/* Contact Form */

#contactForm {
  background-color: white;
  border: none !important;
  box-shadow: none;

}

#linksList {
  justify-content: space-evenly !important;
}

#name {
  border-bottom: #000 !important;
  width: 50%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 100px 0;
  background-color: #49734F !important;
  /* background-image: url('./assets/img/footer-bg.png'); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

#footer {
  clear: both;
}

.bg-light {
  margin-top: 100px !important;
}

.footer img {
  padding-top: 50px;
  width: 5%;
  justify-content: space-between;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.footer h3 {
  align-items: center;
  padding-top: 100px;
  color: white;
}

.banner_card {
  background-image: url("https://wallpapers.com/images/file/beautiful-anime-sunset-sky-rxiu2o0d2wba3sss.jpg");
}

/* Contact */
#disclaimer {
  font-size: 10px;
}

#contact {
  margin-left: 10%;

}

#contact a {
  color: #000;
  text-decoration: none;
}

#contact h1 {
  padding-left: 0;
  padding-top: 10%;
  font: var(--main-font);
  font-size: 40px;
}

#contact h3 {
  padding-top: 10%;
  font: var(--main-font);
  font-size: 20px;
}

#contact h6 {
  font-size: 2vh !important;
  margin-right: 15%;
}

#contact {
  align-items: center !important;
  padding-left: 10% ;
  margin: 0 !important;
  font: var(--main-font);
  display: flex;
  background-color: #49734F;
}

.contact-div {
  position: relative !important;
  display: flex;
}

#smaller-div {
  color: #f8f8f8;
  font-size: larger;
}

#smaller-div a {
  color: #f8f8f8;
}

.form-col {
  padding: 5%;
  /* margin-right: 10% !important; */
  padding-left: 15%;
  width: 100%;
  font: var(--main-font);
  background-image: url("/src/assets/img/contactpic.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  font-size: larger !important;
  align-items: center;
}

.form-col p {
  word-wrap: break-word !important;
}

.form-div form input:not([type='submit']) {
  border-radius: 20px !important;
  opacity: 0.6;
}

.form-div form textarea {
  border-radius: 20px !important;
  opacity: 0.6;
}

.form-div form input {
  border-radius: 20px !important;
}

.form-div form input[type="submit"] {
  background-color: #49734F !important;
}

.active p {
  color: black !important;
}


#textTestimonials {
  margin-bottom: 0 !important;
  margin-top: 10%;
}

.carousel carousel-dark {
  margin-top: 15%;
}

.middleBanner {
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("./assets/img/paisagem.jpg"); */
  background-color: #49734F;
  margin-top: 5%;
}

.middleBanner h1 {
  margin: 0;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  font-size: 32px;
  font-weight: bold;
}

.middleBanner p {
  font-size: 20px;
  color: white;
  opacity: .7;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.carousel-control-next-icon {
  display: none !important;
}

.carousel-control-prev-icon {
  display: none !important;
}


#footer {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

#developed {
  font-size: 12px;
}

@media screen and (max-width: 991px) {
  #sobre {
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 20px !important;
  }

  #sobre_div img {
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 20px !important;
  }
}

@media screen and (max-width: 991px) {
  #sobre {
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 20px !important;
  }

  #sobre_div img {
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 20px !important;
  }
}

@media screen and (max-width: 1320px) {
  #banner-picture {
    height: 92vh !important;
  }
}

@media screen and (max-width: 1160px) {
  #banner-picture {
    height: 50vh !important;
  }
}

@media screen and (max-width: 800px) {
  .contact-div {
    flex-direction: column;
  }
}

@media screen and (max-width: 991px) {
  #HD {
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 20px !important;
  }

  #HD_div img {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 20px !important;
  }
}


@media screen and (max-width: 800px) {
  .form-col {
    margin: 0 auto;
    padding: 0;
  }
}

@media screen and (max-width: 580px) {
  .form-col {
    word-wrap: break-word !important;
  }
}

@media screen and (max-width: 432px) {
  .form-div {
    width: 100% !important;
    margin: auto !important;
  }
}

@media screen and (max-width: 400px) {
  #HD {
    box-shadow: 0px 30px 30px grey;
  }

  #sobre_div img {
    box-shadow: 0px 30px 30px grey;
  }

  #sobre {
    box-shadow: 0px 30px 30px grey;
  }

  #HD_div img {
    box-shadow: 0px 30px 30px grey;
  }
}

@media  screen and (max-width: 320px) {
  #contact {
    width: auto;
  }
  #testimonials col-md-12 {
    width: auto;
    padding: 0 !important;
  }
}


@media  screen and (max-width: 1000px) {
 .banner h1 {
  font-size: 200%;

 }
}

@media  screen and (max-width: 500px) {
  .banner h1 {
   font-size: 150%;
   padding-top: 100px;
  }
 }

 /* @media  screen and (max-width: 991px) {
  #divcollapse {
    margin-left: 31%;
  }
  /* #namecontainer {
    margin-left: 5%;
  } */




 /* @media  screen and (max-width: 990px) {
  #logoMini2 {
    width: 50% !important;
  }
 }

 @media  screen and (max-width: 767px) {
  #logoMini2 {
    width: 100% !important;
  }
 } */

@media screen and (max-width: 978px){
  .form-col {
    padding: 5%;
  }
}

@media screen and (max-width: 878px){
  .form-col {
    padding: 3%;
  }
}

@media screen and (max-width: 800px){
  .contact-div {
    background-color: #49734F;
  }
}

@media screen and (max-width: 1100px ) {
  .banner h1 {
    line-height: 1.5;
  }
}

@media screen and (max-width: 770px) {
  .about-img {
    position: relative;
    clear: both;
  }
}
