.banner2 {
  background-size: cover;
  background-position: center;
  padding: 150px 0;
  background-image:  url("../paisagem.jpg");
  height: 85vh;
}

.banner2 h1 {
  margin: 0;
  color: white;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  font-size: 32px;
  font-weight: bold;
}

.banner2 p {
  font-size: 20px;
  color: white;
  opacity: .7;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}

#blueBtn {
  margin-top: 0 !important;
}

.text-center h6 {
  margin-bottom: 0 !important;
}


.artigo1p {
  padding-top: 0 !important;
}

#homeBtn {
  background-color: #49734F;
  border: none;
  margin-top: 0 !important;
  margin-left: 0 !important;
}

#blueBtn {
  margin-right: 20px !important;
}
