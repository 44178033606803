.navbar2 {
  background-color: white;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: top 0.3s ease-in-out;
}

.navbar2.hidden {
  top: -60px;
}

#nav-links {
  color: #000000;
  text-decoration: none;
  padding: 0 10px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease-in-out;
}

#nav-links:hover {
  border-bottom: 2px solid #000000;
}

#divcollapse {
  width: 100%;

}

#namecontainer {
  width: 100%;

}

#linksDiv {
  align-items: start;
  padding-left: 300px;
}

#name {
  color: var(--mainColor )!important;
  padding-left: 100px;
  padding-top: 0;
  padding-bottom: 0;
}

#logoMini2 {
  width: 80px;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end !important;
}

@media screen and (min-width: 992px) {
  .navbar2.hidden {
    top: 0;
  }
}

@media screen and (min-width: 991px) {
  #name {
    margin-left: -10%;
  }
}

@media screen and (max-width: 991px) {
  #name {
    margin-left: -5%;
    padding-right: 100px;
  }
}
@media screen and (max-width: 435px) {
  .navbar-brand {
    margin-left: 0;
    padding: 0 !important;
  }
}
@media screen and (max-width: 320px) {
  .navbar-brand {
    margin-left: 0;
    padding: 0 !important;
  }
}

@media screen and (max-width: 700px) {
  #linksDiv {

    padding-left: 0 !important;
  }
}

@media screen and (min-width: 1570px) {
  #namecontainer {
    margin-left: 10%;
  }
}
@media screen and (max-width: 436px) {
  #namecontainer {
    margin-left: 1%;
  }
}
