.card img {
  height: 40vh !important;

  display: flex;

}

#cards h4 {
  font: var(--main-font);
  color: #4d4d4d;
}

.card {
  display: flex;
  border-radius: 15px;
  width: 30%;

}

.card .btn {
  background-color: #49734F;
  border: none;
}

#cards {
  margin-top: 0 !important;
  color: #4d4d4d;
  display: flex;
}

#blueBtn {

  border: none;
  background-color: white;
  border: 1px solid #49734F;
  color:#49734F;
}

#blueBtn:hover {
  background-color: #49734F !important;
  color: white;
}

#blueBtn:active {
  background-color: #49734F;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

#cardartigo1 {
  margin-right: 0 !important;
  -webkit-column-width: 500px;
  -moz-column-width: 500px;
       column-width: 500px;
}


.container h2 {
    font-size: 40px;
    font-weight: 700;

}

#line {
  width: 100%;
  color: #49734F;
  background-color: #49734F;
  opacity: 1;
  align-items:end;
  text-align: center;
  height: 5px;
}

#btnCard1 {
  background-color: white;
  border: 1px solid #49734F;
  color:#49734F;
}

#btnCard1:hover {
  background-color: #49734F !important;
  color: white;
}

#btnCard1:active {
  background-color: #49734F;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}


.artigo1p {
  padding: 100px;
}

@media screen and (max-width: 620px) {
  .artigo1p {
    padding: 30px
  }
}

@media screen and (max-width: 770px) {
  #artigos {
    padding-left: 37%;
  }
}

@media screen and (max-width: 1600px) {
  .card {
    width: 30%;
  }
}

@media screen and (max-width: 1570px) {
  .card {
    width: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .card {
    width: 30%;
  }
}

@media screen and (max-width: 770px) {
  .card {
    width: 100%;
  }
}
